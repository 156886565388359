import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/slick-carousel/slick/slick.css");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/slick-carousel/slick/slick-theme.css");
import(/* webpackMode: "eager" */ "/usr/src/app/src/components/atoms/customImage/index.jsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/components/molecules/AppRedirectionBanner/AppRedirectionBanner.jsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/components/molecules/stickyFooter/index.jsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/components/organisms/footer/footerInfo/chatWithUs.jsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/components/organisms/footer/subscription/index.jsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/components/organisms/header/index.jsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/components/organisms/headerPlpPdpMobile/index.jsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/containers/productListingPage/index.jsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/containers/productListingPage/longDescription/index.jsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/containers/productListingPage/microSite.jsx")