"use client";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { READ_LESS, READ_MORE } from "../../../constants/common/common";

/** */
export const LongDescription = ({ longDescription }) => {
  const [showMore, setShowMore] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [buttonLabel, setButtonLabel] = useState(READ_MORE);

  // Ensure this runs only on the client
  useEffect(() => {
    setIsMounted(true);
  }, []);

  /**
   * This method is handling readmore/readless footer description
   */
  const onHandleViewMore = () => {
    setShowMore((prevShowMore) => {
      const newShowMore = !prevShowMore;
      setButtonLabel(newShowMore ? READ_LESS : READ_MORE);
      return newShowMore;
    });
  };

  useEffect(() => {
    if (isMounted) {
      const longDescriptionSec = document.getElementById("longDescription");
      if (longDescriptionSec && longDescriptionSec.children.length > 0) {
        const allContent = longDescriptionSec.children;
        console.log(allContent, "allContent");
        const firstElements = document.createElement("div");
        const hiddenSec = document.createElement("div");
        firstElements.classList = "w-full";
        hiddenSec.classList = `w-full ${showMore ? "" : "hidden"}`;
        Array.from(allContent).forEach((element, index) => {
          if (index <= 1) {
            firstElements.appendChild(element);
          } else {
            hiddenSec.appendChild(element);
          }
        });
        longDescriptionSec.innerHTML = "";
        longDescriptionSec.appendChild(firstElements);
        longDescriptionSec.appendChild(hiddenSec);
      }
    }
  }, [isMounted, showMore]);

  return (
    <>
      <article
        className="p-16 md:p-40 md:pb-0"
        id="longDescription"
        dangerouslySetInnerHTML={{ __html: longDescription }}
      />
      <button
        type="button"
        onClick={onHandleViewMore}
        className="mr-40 inline-flex justify-end text-14 font-500 text-blue-100"
      >
        {buttonLabel}
      </button>
    </>
  );
};

LongDescription.propTypes = {
  longDescription: PropTypes.string.isRequired,
};
